import { useEffect, useState } from "react";
import Slider from "react-slick";
import b3 from '../../../src/assets/img/b3-logo-1-slide.png';
import b3Customer from '../../../src/assets/img/b3.png';
import bigDataCorp from '../../../src/assets/img/big-data-corp-slide.png';
import certiSign from '../../../src/assets/img/certisign-black-slide.png';
import clickSign from '../../../src/assets/img/click-sign-slide.png';
import docuSign from '../../../src/assets/img/docusign-1-slide.png';
import iaas from '../../../src/assets/img/iaas.png';
import itau from '../../../src/assets/img/itau.png';
import microsoftStartup from '../../../src/assets/img/microsoft-startups-slide.png';
import serPro from '../../../src/assets/img/serpro-slide.png';
import vinci from '../../../src/assets/img/vinci.png';
import virgo from '../../../src/assets/img/virgo.png';
// import weeklyTech from '../../../src/assets/img/weekly-tech.png';


export function Content() {

    const stepVideo = "https://firebasestorage.googleapis.com/v0/b/golizatech.appspot.com/o/step-goliza.mp4?alt=media&token=74bf8564-6d7c-47b5-99e5-051c02c2d673"
    const [lizaFoF, setLizaFoF] = useState(true);
    const [lizaBroker, setBroker] = useState(false);

    useEffect(() => {
    }, [])

    const handleLizaFoF = () => {
        setLizaFoF(true);
        setBroker(false);
    }

    const handleLizaBroker = () => {
        setBroker(true);
        setLizaFoF(false);
    }


    const openSchedule = () => {
        window.open('https://calendly.com/penna26/liza', '_self');
    }

    const openOtherSolutions = () => {
        window.location.href = `other-solutions`;
    }

    let SliderSettings = {
        adaptiveHeight: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        }]
    }

    return (
        <>
            <div className="steps">
                <div className="content-steps">
                    <video autoPlay muted loop style={{maxWidth: '100%', border: '1px solid #ffffff'}}>
                        <source src={stepVideo} width={'100%'} height={'auto'} type="video/mp4"></source>
                    </video>
                    <div style={{marginBottom: '1rem'}}>
                        <button type="button" className="step-button" onClick={() => openSchedule()}>Agende com um especialista</button>
                    </div>
                </div>
            </div>
            <div className="phrase" id="lizafunds"> 
                <div className="content-phrase animate__animated animate__fadeInDown">
                    <h3>Diferenciais:</h3>
                    <div className="line-pharse"></div>
                </div>
                <div className="content-phrase animate__animated animate__fadeInDown">
                    <p><span>Integração com a CVM</span> para consulta de dados públicos dos fundos cotistas e investidos;</p>
                    <p><span>Preenchimento automático</span> de qualquer Ficha Cadastral, Termo de Adesão ou qualquer documento docx, pdf ou xlsx;</p>
                    <p><span>Integração</span> para assinatura digital via Clicksign, Certisign ou Docusign do cliente;</p>
                    <p><span>Plataforma</span> de comunicação e aprovação com a Gestora ou Corretora.</p>
                    <p><span>Painel de controle</span> para consolidação de todos fluxos de cadastros recebidos em um único local (ideal para Corretoras e Gestoras que recebem fluxos de cadastro de FoF)</p>
                </div>
            </div>

            {/*  */}
            <div className="content-other-solutions animate__animated animate__fadeInLeft" style={{ background: '#fff' }}>
                <div className="content-other-solutions-buttons">
                    <div className="other-solution-item">
                        <button className={lizaFoF ? 'active' : ''} onClick={() => handleLizaFoF()}>
                            <h1>Liza FoF</h1>
                            <span>Saiba mais</span>
                        </button>
                    </div>
                    <div className="other-solution-item">
                        <button className={lizaBroker ? 'active' : ''} onClick={() => handleLizaBroker()}>
                            <h1>Liza Corretoras</h1>
                            <span>Saiba mais</span>
                        </button>
                    </div>
                    <div className="other-solution-item">
                        <button href="/other-solutions" onClick={() => openOtherSolutions()}>
                            <h1>Outras Soluções</h1>
                            <span>Saiba mais</span>
                        </button>
                    </div>
                </div>
            </div>
            {lizaFoF && (
                <div className="content-wrapper-other-solutions animate__animated animate__fadeInLeft">
                    <div style={{ marginTop: '10rem', textAlign: 'center' }}>
                        <h2>Esteira de preenchimento, assinatura e aprovação de formulários para cadastro de um fundo em outro fundo.</h2>
                    </div>
                    <hr></hr>
                    <div style={{ marginTop: '1rem', textAlign: 'center', paddingBottom: '5rem', width: '99%', margin: '0 auto' }}>
                        <div className="area-product-video">
                            <h4>Demonstração</h4>
                            <iframe style={{ maxWidth: '100%' }} width={'800px'} height={'450px'}
                                src="https://www.youtube.com/embed/kY_rTmJYoLo?si=LQ_wU6h1rBz2IHo9" title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            )}
            {lizaBroker && (
                <div className="content-wrapper-other-solutions animate__animated animate__fadeInLeft">
                    <div style={{ height: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h2>Esteira de preenchimento, assinatura e aprovação de formulários para cadastro de um fundo em uma corretora.</h2>
                    </div>
                    <hr></hr>
                    <div style={{textAlign: 'center' }}>
                    </div>
                    {/* <div style={{ marginTop: '1rem', textAlign: 'center', paddingBottom: '5rem' }}>
                        <div className="area-product-video">
                            <h4>Demonstração</h4>
                            <iframe style={{ maxWidth: '100%' }} width={'800px'} height={'450px'}
                                src="https://www.youtube.com/embed/kY_rTmJYoLo?si=LQ_wU6h1rBz2IHo9" title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        </div>
                    </div> */}
                </div>
            )}

            <div id="schedule-a-demo">
                <div className="contentDemo">
                    <h1 className="titleSchedule">Agende uma demonstração</h1>
                    <button type="button" className="button-schedule" onClick={() => openSchedule()}>Agende com um especialista</button>
                </div>
            </div>

            <div id="customers">
                <div className="contentCustomer">
                    <h1 className="title">Clientes</h1>
                </div>
                <div className="bodyCustomer">
                    <div className="item-customer">
                        <a href="https://virgo.inc/" target="_blank" rel="noopener noreferrer"><img src={virgo} width="auto" alt="Virgo"
                            height="auto" /></a>
                    </div>
                    <div className="item-customer">
                        <a href="https://iaasbr.com/" target="_blank" rel="noopener noreferrer"><img src={iaas} width="auto" alt="Iaas br"
                            height="auto" /></a>
                    </div>
                    {/* <div className="item-customer">
                        <a href="https://www.legacycapital.com.br/" target="_blank" rel="noopener noreferrer"><img src={legacy} width="auto"
                            alt="Legacy Capital" height="auto" /></a>
                    </div> */}
                    <div className="item-customer">
                        <a href="https://www.itau.com.br/" target="_blank" rel="noopener noreferrer"><img src={itau} width="auto"
                            alt="Itaú" height="auto" /></a>
                    </div>
                    <div className="item-customer">
                        <a href="https://www.vincipartners.com/" target="_blank" rel="noopener noreferrer"><img src={vinci} width="auto"
                            alt="Itaú" height="auto" /></a>
                    </div>
                    <div className="item-customer">
                        <a href="https://www.b3.com.br/" target="_blank" rel="noopener noreferrer"><img src={b3Customer} width="auto"
                            alt="B3" height="auto" /></a>
                    </div>
                </div>
            </div>

            <div id="partners">
                <div className="contentPartners">
                    <h1 className="title">Parceiros</h1>
                </div>
                <Slider {...SliderSettings} className="customer-logos slider">
                    <div className="slide">
                        <a href="https://www.b3.com.br/pt_br/solucoes/plataformas/middle-e-backoffice/imercado/provedores-de-solucoes/"
                            target="_blank" rel="noopener noreferrer"><img src={b3} alt="b3" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://www.clicksign.com/" target="_blank" rel="noopener noreferrer"><img src={clickSign}
                            alt="click sign" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://www.docusign.com/" target="_blank" rel="noopener noreferrer"><img src={docuSign}
                            alt="docusign" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://www.serpro.gov.br/" target="_blank" rel="noopener noreferrer"><img src={serPro}
                            alt="serpro" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://bigdatacorp.com.br/" target="_blank" rel="noopener noreferrer"><img src={bigDataCorp}
                            alt="big data corp" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://certisign.com.br/" target="_blank" rel="noopener noreferrer"><img src={certiSign}
                            alt="certisign" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://foundershub.startups.microsoft.com/" target="_blank" rel="noopener noreferrer"><img
                            src={microsoftStartup} alt="Microsoft for Startups Founders Hub" /></a>
                    </div>
                </Slider>
            </div>

            {/* <div id="midia">
                <div className="contentCustomer">
                    <h1 className="title">goLiza na Mídia</h1>
                </div>
                <div className="bodyMidia">
                    <Link to={'https://juliadeluca.substack.com/p/startups-to-watch-38c'} target="_blank" rel="noopener noreferrer">
                        <img src={weeklyTech} alt="Startups to Watch" />
                    </Link>
                </div>
            </div> */}
        </>
    )
}