import { Link } from 'react-router-dom';
import cubo from '../../assets/img/cubo.png';
import darwin from '../../assets/img/darwin.svg';
import fisher from '../../assets/img/fisher.png';
import linkedin from '../../assets/img/linkedin.png';
import visaPartner from '../../assets/img/visa-partner.png';
import youtube from '../../assets/img/youtube.png';

export function Footer() {
    return (
        <>
            <div id="footer">

                <div id="footerInfo">

                    <div className="footerLinks">
                        <a href="https://s3-sa-east-1.amazonaws.com/dev-goliza-tech-files/416f6e22-df28-4d3d-a9d7-432731c72ab3.pdf"
                            target="_blank" rel="noopener noreferrer">Política de Privacidade</a>
                        <a href="https://goliza.s3.amazonaws.com/termos/goliza-termos-de-uso.pdf" target="_blank" rel="noopener noreferrer">Termo de
                            Uso</a>
                    </div>


                    <div className="itemFooterContent">
                        <div>
                            <img src={cubo} width="52px" height="auto" alt="cubo startup" />
                        </div>
                        <div>
                            <span>Siga-nos</span>
                        </div>
                        <div style={{ display: 'flex', flexCirection: 'row', gap: `4px` }}>
                            <a href="https://www.linkedin.com/company/liza/" target="_blank" rel="noopener noreferrer">
                                <img src={linkedin} width="25px" height="auto" alt="linkedin" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCFXZoGoH2QJAtqFZbufREzA" target="_blank" rel="noopener noreferrer">
                                <img src={youtube} width="25px" height="auto" alt="youtube" />
                            </a>
                        </div>
                        <div>
                            <span>
                                Partners
                            </span>
                        </div>
                        <div>
                            <Link to={"https://www.fishervb.com/"} target={"_blank"} rel={"noopener noreferrer"}>
                                <img src={fisher} width="80px" height="auto" alt="fisher" />
                            </Link>
                        </div>
                        <div>
                            <img src={visaPartner} width="120px" height="auto" alt="visa-partners" />
                        </div>
                        <div>
                            <Link to={"https://www.darwinstartups.com/"} target={"_blank"} rel={"noopener noreferrer"}>
                                <img src={darwin} width="120px" height="auto" alt="darwin" />
                            </Link>
                        </div>
                    </div>

                    <div className="footerCompany">
                        <p>Liza Conexoes Digitais LTDA</p>
                        <p>CNPJ: 30.936.471/0001-77</p>
                        <p>Email: atendimento@goliza.com.br</p>
                    </div>
                </div>

                <div className="footerCopyright">
                    <div class="footer" style={{textAlign: 'center', width: '80%', margin: '0 auto'}}>
                        <div>
                            <p style={{color:'white'}}>Todos os direitos reservados. © Liza Conexões Digitais Ltda, 2024.
                                Nenhuma
                                parte deste site ou
                                seu conteúdo pode ser reproduzida ou distribuída de qualquer forma ou por qualquer meio, sem o
                                consentimento prévio por escrito do proprietário dos direitos autorais. Para obter informações
                                sobre
                                nossa política de privacidade, <a href="https://s3-sa-east-1.amazonaws.com/dev-goliza-tech-files/416f6e22-df28-4d3d-a9d7-432731c72ab3.pdf"
                                    target="_blank" style={{ color: 'var(--primaryColor)', cursor: 'pointer', fontWeight: '500' }}>consulte aqui</a>.</p>
                        </div>
                        <div>
                            <p style={{color: 'white'}}>All rights reserved. © Liza Conexões Digitais Ltda, 2024. No part of this
                                site or its content may be reproduced or distributed in any form or by any means, without the
                                prior
                                written permission of the copyright owner. For information on our privacy policy, <a href="https://s3-sa-east-1.amazonaws.com/dev-goliza-tech-files/416f6e22-df28-4d3d-a9d7-432731c72ab3.pdf"
                                    target="_blank" style={{ color: 'var(--primaryColor)', cursor: 'pointer', fontWeight: '500' }}>please click
                                    here</a>.</p>
                        </div>
                    </div>
                    {/* <p style={{textAlign: 'center', margin: '1rem 0'}}>© goLiza - Todos os direitos reservados 2024.</p> */}
                </div>

            </div>
        </>
    )
}